@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.creator {
  .btm {
    text-align: center;
    strong {
      line-height: 3;
    }
  }
  &-list {
    &-ele {
      position: relative;
      &:after {
        background: url(../images/common/icon_play.png) left top no-repeat;
        background-size: contain;
        content: '';
        height: 50px;
        left: 50%;
        position: absolute;
        top: 50%;
        transition: opacity 0.5s;
        transform: translate(-50%, -50%);
        width: 50px;
        pointer-events: none;
      }
    }
  }
}
@media (--pc) {
  .creator {
    &-list {
      @mixin center;
      margin: 40px auto 0;
      &-ele {
        cursor: pointer;
        &.hoz {
          width: 415px;
          /* height: 234px; */
          margin: 0 15px;
          img {
            width: 100%;
          }
        }
        &.ver {
          width: 234px;
          /* height: 350px; */
          margin: 0 15px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .btn {
      margin: 50px auto 0;
      @mixin btn 290px, 62px;
      font-size: rem(16);
      padding-left: 100px;
      &:after {
        right: 25px;
        font-size: rem(11);
      }
    }
  }
}

@media (--sp) {
  .creator {
    .btm {
      margin-top: vw(40);
      white-space: nowrap;
    }
    &-list {
      margin: vw(40) auto 0;
      &-ele {
        margin: 0 auto vw(30);
        &.hoz {
          width: vw(530);
          height: vw(300);
          img {
            width: 100%;
          }
        }
        &.ver {
          width: vw(297);
          /* height: vw(444); */
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .btn {
      margin: vw(60) auto 0;
      @mixin btn vw(560), vw(92);
      font-size: vw(26);
      padding-left: vw(190);
      &:after {
        right: vw(40);
        font-size: vw(22);
      }
    }
  }
}
