@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.seminar {
  &-ele {
    position: relative;
    color: white;
    &--new {
      &:before {
        content: 'NEW';
        @mixin center;
        background-color: $base_blue;
        border-radius: 50%;
        position: absolute;
        color: white;
        z-index:3;
      }
    }
    &-bg {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      &--left {
        background-image: url(../images/top/seminar_01.jpg);
      }
      &--right {
        background-image: url(../images/top/seminar_02.jpg);
      }
    }

    a {
      display: block;
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      h3 {
        text-align: center;
        font-weight: normal;
      }
    }

  }
  &-content{
    position:relative;
    z-index: 2;
    @mixin center;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
}
@media (--pc) {
  .seminar {
    .sec-inner {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    .sec-subtl{
      text-align: left;
    }
    
    &-list {
      @mixin center;
    }

    &-ele {
      width: 300px;
      height: 300px;
      margin: 0 25px;

      &--new {
        &:before {
          top: 55px;
          left: 0;
          width: 40px;
          height: 40px;
          font-size: rem(12);
        }
      }

      &-bg {
        transition: transform 0.6s ease-in-out;
      }

      &:hover {
        .seminar-ele-bg {
          transform: scale(1.1);
        }
      }

      a {
        h3 {
          font-size: rem(24);
          line-height: 1.5;
          letter-spacing: 4px;
        }
      }
    }

    &-date {
      margin-top: 10px;
    }

    .btn {
      @mixin btn 170px, 50px;
      position: absolute;
      top: 170px;
      font-size: rem(16);
      padding-left: 30px;
      &:after {
        right: 15px;
        font-size: rem(11);
      }
    }
  }
}

@media (--sp) {
  .seminar {
    &-list{
      margin: vw(60) auto 0;
    }
    &-ele {
      width: vw(415);
      height: vw(415);
      margin: 0 auto vw(30);

      &--new {
        &:before {
          top: vw(55);
          left: vw(-20);
          width: vw(80);
          height: vw(80);
          font-size: vw(24);
        }
      }


      a {
        h3 {
          font-size: vw(34);
          line-height: 1.5;
          letter-spacing: vw(4);
        }
      }
    }

    &-date {
      margin-top: vw(10);
    }

    .btn {
      @mixin btn vw(560), vw(90);
      font-size: vw(26);
      padding-left: vw(195);
      margin: vw(65) auto 0;
      &:after {
        right: vw(36);
        font-size: vw(22);
      }
    }
  }
}
