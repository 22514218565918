@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';
.contact {
  .btm {
    text-align: center;
  }
}
@media (--pc) {
  .contact {
    .contact-btn {
      display: flex;
      align-items: center;
      margin: 45px auto 0;
      li {
        margin: 0 auto;
      }
    }
    .btm {
      font-size: rem(18);
      line-height: 1.7;
      letter-spacing: 2px;
      margin-top: 35px;
    }
    .btn {
      @mixin btn 485px, 100px;
      padding-left: 155px;
      font-size: rem(20);
      /* &-black {
        padding-left: 130px;
      } */
      &:after {
        right: 30px;
        font-size: rem(11);
      }
      i {
        display: block;
        margin-right: 25px;
      }
      small {
        font-size: rem(14);
      }
      /* .glyphs-download {
        font-size: rem(22);
      } */
      .glyphs-letter {
        font-size: rem(24);
      }
    }
  }
}

@media (--sp) {
  .contact {
    .contact-btn {
      margin: vw(45) auto 0;
    }
    .btm {
      font-size: vw(28);
      line-height: 1.7;
      margin-top: vw(45);
      white-space: nowrap;
    }
    .btn {
      @mixin btn vw(555), vw(120);
      padding-left: vw(40);
      margin: 0 auto vw(30);
      &:after {
        right: vw(35);
        font-size: vw(22);
      }
      small {
        font-size: vw(24);
      }
      i {
        display: block;
      }
      .glyphs-download {
        font-size: vw(45);
        margin-right: vw(40);
      }
      .glyphs-letter {
        font-size: vw(40);
        margin-right: vw(95);
      }
    }
  }
}
