@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.swiper-pagination {
  &-bullet {
    border: 1px solid white;
    background-color: transparent !important;
    transition: background 0.3s ease;
    cursor: pointer;
    &-active {
      background-color: #fff !important;
    }
  }
}
.swiper-button {
  border-radius: 50%;
  border: 1px solid $base_blue;
  background-image: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: $base_blue;
  transition: border 0.3s ease-in-out;
  position: relative;
  i {
    display: block;
    transition: color 0.3s ease-in-out;
  }
  &-prev {
    i {
      transform: rotate(180deg);
    }
  }
}
.swiper-pagination {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  white-space: nowrap;
  &-bullet {
    border: 1px solid $base_blue;
    opacity: 1 !important;
    transition: border 0.3s ease;
    &-active {
      background-color: $base_blue !important;
    }
    /* &:hover{
      border: 1px solid black;
    } */
  }
}
@media (--pc) {
  .swiper-pagination {
    &-bullet {
      width: 6px !important;
      height: 6px !important;
      margin: 0 5px !important;
    }
  }

  .swiper-button {
    width: 74px !important;
    height: 74px !important;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.5;
    }
    i {
      font-size: rem(20);
    }
  }
}

@media (--sp) {
  .swiper-pagination {
    &-bullet {
      width: vw(12) !important;
      height: vw(12) !important;
      margin: 0 vw(10) !important;
    }
  }

  .swiper-button {
    width: vw(74) !important;
    height: vw(74) !important;
    i {
      display: block;
      margin-top: vw(4);
      font-size: vw(20);
    }
    /* &-next{
      i{
        transform: translateX(vw(4));
      }
    }*/
    &-prev {
      i {
        transform: translate(vw(-2), vw(-2)) rotate(180deg);
      }
    }
  }
}
