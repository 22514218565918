@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';
.about {
  .swiper-pagination {
    &-bullet {
      border: 1px solid $base_blue;
      &-active {
        background-color: $base_blue !important;
      }
    }
  }

  &-wrapper {
    position: relative;
  }

  &-monitor {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/top/monitor.png);
    z-index: 2;
    pointer-events: none;
  }

  &-pagination {
    left: 50%;
    transform: translate(-50%, 0) !important;
  }

  &-slide {
    figure {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    background-color: $light_blue;
  }
}
@media (--pc) {
  .about {
    .btm {
      margin-top: 50px;
    }
    &-wrapper {
      width: 1000px;
      margin: 45px auto 0;
    }
    &-monitor {
      width: 100%;
      height: 487px;
    }
    &-slider {
      width: 683px;
      margin: 0 auto 40px !important;
      overflow: hidden;
      padding-top: 25px !important;
    }
    &-slide {
      width: 683px !important;
      figure {
        height: 388px;
        margin-bottom: 100px;
      }
      p {
        text-align: center;
        font-size: rem(16);
      }
    }
  }
}

@media (--sp) {
  .about {
    .btm {
      margin-top: vw(50);
    }
    &-wrapper {
      width: vw(708);
      margin: vw(50) auto 0;
    }
    &-monitor {
      width: 100%;
      height: vw(345);
    }
    &-slider {
      width: vw(480);
      margin: 0 auto vw(40) !important;
      padding-top: vw(18) !important;
    }
    &-slide {
      width: vw(480) !important;
      figure {
        width: vw(480);
        height: vw(275);
        margin-bottom: vw(95);
      }
      p {
        text-align: left;
      }
    }
  }
}
