@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';
.feature{
  &-ele{
    text-align: center;
    figure {
      @mixin center;
      margin: 0 auto;
    }
    h3{
      border-bottom: 1px solid $light_gray;
      white-space: nowrap;
    }
    p{
      text-align: left;
      line-height: 2;
    }
  }
}

@media (--pc) {
  .feature {
    &-list {
      display: flex;
      justify-content: space-between;
      margin: 50px auto 0;
    }
    &-ele {
      width: 300px;
      figure {
        width: 290px;
        height: 120px;
        .heart {
          width: auto;
          height: 98px;
        }
        .pursuit {
          width: auto;
          height: 86px;
        }
        .watch {
          width: auto;
          height: 102px;
        }
      }
      h3 {
        line-height: 1.4;
        font-size: rem(22);
        padding-bottom: 12px;
        margin: 12px 0;
        letter-spacing: 2px;
      }
      p {
        font-size: rem(16);
      }
    }
    .btn {
      margin: 50px auto 0;
      @mixin btn 360px, 62px;
      font-size: rem(16);
      padding-left: 40px;
      &:after {
        right: 25px;
        font-size: rem(11);
      }
    }
  }
}

@media (--sp) {
  .feature {
    &-ele {
      margin: vw(90) auto 0;
      width: vw(620);
      figure {
        @mixin center;
        margin: 0 auto;
        .heart {
          width: auto;
          height: vw(147);
        }
        .pursuit {
          width: auto;
          height: vw(133);
        }
        .watch {
          width: auto;
          height: vw(149);
        }
      }
      h3 {
        line-height: 1.4;
        font-size: vw(30);
        padding-bottom: vw(20);
        margin: vw(30) 0 vw(20);
        letter-spacing: vw(4);
      }
    }
    .btn {
      margin: vw(60) auto 0;
      @mixin btn vw(560), vw(92);
      font-size: vw(26);
      padding-left: vw(60);
      &:after {
        right: vw(36);
        font-size: vw(14);
      }
    }
  }
}
