@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.result {
  .swiper-slide {
    opacity: 0.2;
    transition: opacity 0.3s ease;
    &-active {
      opacity: 1;
    }
  }
  &-slider-wrapper {
    position: relative;
  }
  &-slide-container {
    a {
      display: block;
    }
    h3 {
      border-bottom: 1px solid #d2d2d2;
      text-align: center;
    }
    dt {
      font-weight: bold;
    }
  }
  .btm {
    text-align: center;
    white-space: nowrap;
  }
}
@media (--pc) {
  .result {
    .swiper-slide {
      &-next {
        opacity: 1;
      }
    }
    &-slider {
      width: 1000px;
      margin: 70px auto 0 !important;
      overflow: visible !important;
    }
    &-slide-container {
      width: 470px !important;
      margin-right:100px !important;
      a {
        transition: opacity 0.3s ease;
        &:hover {
          opacity: 0.5;
        }
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        height: 75px;
        padding-bottom: 12px;
      }
      .tag{
        font-size: rem(14);
        line-height: 1;
        padding: 5px 15px;
        border: 1px solid $base_blue;
      }
      .nysno{
        width: 200px;
        height: auto;
      }
      .keisuke{
        width: 126px;
        height: auto;
      }
      .bravia{
        width: 135px;
        height: auto;
      }
      .alfred{
        width: 192px;
        height: auto;
      }
      .antwerp{
        width: 168px;
        height: auto;
      }
      .smartmedia{
        width: 236px;
        height: auto;
      }
      dt {
        font-size: rem(20);
        margin-bottom: 10px;
      }
      dd {
        font-size: rem(16);
        line-height: 2;
      }
    }
    &-btn-prev {
      left: -90px !important;
    }
    &-btn-next {
      right: -120px !important;
    }
  }
}

@media (--sp) {
  .result {
    .btm {
      margin: vw(40) auto 0;
    }
    &-slider {
      margin: vw(70) auto 0 !important;
    }
    &-slide-container {
      width: vw(590) !important;
      margin: 0 vw(50) !important;
      h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: vw(25);
        margin-bottom: vw(30);
        height: vw(104);
      }
      .tag{
        font-size: vw(24);
        line-height: 1;
        padding: vw(10) vw(15);
        border: 1px solid $base_blue;
      }
      .nysno{
        width: vw(316);
        height: auto;
      }
      .keisuke{
        width: vw(189);
        height: auto;
      }
      .bravia{
        width: vw(135);
        height: auto;
      }
      .alfred{
        width: vw(233);
        height: auto;
      }
      .antwerp{
        width: vw(242);
        height: auto;
      }
      .smartmedia{
        width: vw(282);
        height: auto;
      }
      dt {
        font-size: vw(30);
        margin-bottom: vw(20);
      }
      dd {
        font-size: vw(26);
        line-height: 2;
      }
    }
    &-btn-prev {
      left: vw(4) !important;
    }
    &-btn-next {
      right: vw(4) !important;
    }
  }
}
