@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.news {
  .news-ele {
    border-top: 1px solid $light_gray;
    &:nth-last-child(1) {
      border-bottom: 1px solid $light_gray;
    }
    p {
      line-height: 1.5;
    }
  }

  .btn {
    display: none;
  }
  .chip {
    flex-shrink: 0;
  }
}
@media (--pc) {
  .news {
    .news-list {
      margin: 50px auto 0;
    }
    .news-ele {
      padding: 24px 0;
      dt {
        flex-shrink: 0;
        span {
          flex-shrink: 0;
          white-space: nowrap;
        }
      }
    }
  }
}

@media (--sp) {
  .news {
    .news-list {
      margin: vw(30) auto 0;
    }
    .news-ele {
      padding: vw(30) 0;
    }
  }
}
