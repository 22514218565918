@charset "utf-8";
@import './_config/_mixin.css';
@import './_config/_fonts.css';
@import './_config/_media.css';
@import './_config/_reset.css';

@import './_top_modules/_mv.css';
@import './_top_modules/_about.css';
@import './_top_modules/_feature.css';
@import './_top_modules/_result.css';
@import './_top_modules/_news.css';
@import './_top_modules/_seminar.css';
@import './_common_modules/_contact.css';
@import './_top_modules/_format.css';
@import './_top_modules/_company.css';
@import './_top_modules/_creator.css';
@import './_common_modules/_carousel.css';

/* .mv-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  video {
    height: auto;
    width: 100%;
  }
  &--vertical {
    video {
      width: auto;
      height: 100%;
    }
  }
} */

@media (--pc) {
  .btm {
    font-size: rem(18);
    line-height: 1.7;
    letter-spacing: 2px;
    margin-top: 35px;
  }

  .news-band {
    padding: 28px 0 22px;
    .news-ele {
      p {
        line-height: 1.5;
      }
    }
  }
  .news-list {
    width: 1000px;
    margin: 0 auto;
    a {
      @mixin alphaHover;
    }
    a,
    dl,
    dt,
    dd {
      display: flex;
      /* align-items: center; */
    }
    dt {
      position: relative;
      padding-right: 20px;
      flex-shrink: 0;
      span {
        flex-shrink: 0;
        white-space: nowrap;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 24px;
        background-color: black;
        top: 2px;
        right: 0;
      }
    }

    .cat {
      width: 102px;
      height: 26px;
      border: 1px solid #8a8a8a;
      font-size: rem(12);
      margin-right: 20px;
      @mixin center;
    }
    .time {
      font-weight: bold;
      font-size: rem(16);
    }

    dd {
      padding-left: 20px;
      font-size: rem(16);
    }

    .chip {
      color: white;
      background-color: $base_blue;
      width: 44px;
      height: 20px;
      font-size: rem(12);
      @mixin center;
      display: inline-flex;
    }
  }

  .fmvideo-container {
    width: 415px;
    height: 234px;
    &--vertical {
      width: 234px;
      height: 415px;
    }
  }
}

@media (--sp) {
  .btm {
    line-height: 1.7;
  }

  .news-band {
    width: 100%;
    box-sizing: border-box;
    padding: vw(40) vw(30);
    p {
      line-height: 1.5;
    }
  }
  .news-list {
    width: 100%;
    margin: 0 auto;
    a,
    dt {
      display: flex;
      align-items: center;
    }

    .cat {
      width: vw(140);
      height: vw(40);
      border: 1px solid #8a8a8a;
      font-size: vw(24);
      margin-right: vw(15);
      @mixin center;
    }
    .time {
      font-weight: bold;
      font-size: vw(26);
    }

    dd {
      margin-top: vw(10);
      font-size: vw(26);
    }

    .chip {
      color: white;
      background-color: $base_blue;
      width: vw(74);
      height: vw(30);
      font-size: vw(24);
      @mixin center;
      display: inline-flex;
    }
  }
}
