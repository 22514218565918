/* そのまま幅と高さが入る */
@define-mixin bg $path {
  display: block;
  background-image: url($path);
  width: width($path);
  height: height($path);
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: height($path);
  overflow: hidden;
  font-size: 0;
}
@define-mixin bgKV $path {
  display: inline-block;
  background-image: url($path);
  width: widthP($path);
  height: heightP($path);
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: heightP($path);
  overflow: hidden;
  font-size: 0;
}
@define-mixin bgTB $path {
  display: inline-block;
  background-image: url($path);
  width: widthTB($path);
  height: heightTB($path);
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: heightTB($path);
  overflow: hidden;
  font-size: 0;
}

/* vw */
@define-mixin bgV $path {
  display: block;
  background-image: url($path);
  width: widthV($path);
  height: heightV($path);
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: heightV($path);
  box-sizing: border-box;
  overflow: hidden;
  font-size: 0;
}

/* 同一アスペクト比 by width */
@define-mixin bgR $path {
  display: block;
  background-image: url($path);
  background-size: cover;
  width: 100%;
  max-width: width($path);
  overflow: hidden;
  font-size: 0;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: aspect($path);
  }
}
@define-mixin alphaHover {
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
}
@define-mixin btn $w, $h {
  width: $w;
  height: $h;
  border-radius: calc($h * 0.5);
}

@define-mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}
$base_blue: #0ba0f5;
$dark_gray: #444444;
$light_blue: #eaf7ff;
$light_gray: #e5e5e5;
