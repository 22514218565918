@font-face {
    font-family: "glyphs";
    src: url("../fonts/glyphs.eot?348ed0e9afa6bd3cbf87bba1a4469fe2?#iefix") format("embedded-opentype"),
url("../fonts/glyphs.woff2?348ed0e9afa6bd3cbf87bba1a4469fe2") format("woff2"),
url("../fonts/glyphs.woff?348ed0e9afa6bd3cbf87bba1a4469fe2") format("woff"),
url("../fonts/glyphs.ttf?348ed0e9afa6bd3cbf87bba1a4469fe2") format("truetype"),
url("../fonts/glyphs.svg?348ed0e9afa6bd3cbf87bba1a4469fe2#glyphs") format("svg");
}

i[class^="glyphs-"]:before, i[class*=" glyphs-"]:before {
    font-family: glyphs !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphs-arrow:before {
    content: "\f101";
}
.glyphs-download:before {
    content: "\f102";
}
.glyphs-heart:before {
    content: "\f103";
}
.glyphs-letter:before {
    content: "\f104";
}
.glyphs-link:before {
    content: "\f105";
}
.glyphs-pursuit:before {
    content: "\f106";
}
.glyphs-watch:before {
    content: "\f107";
}
