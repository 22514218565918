@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.format {
  overflow: hidden;
  .swiper-slide {
    opacity: 0.2;
    transition: opacity 0.3s ease-in-out;
    &-active {
      opacity: 1;
    }
  }
  &-video {
    position: relative;
    margin: 0 auto;
    &-container {
      @mixin center overflow: hidden;
      .imagebox {
        background-color: #fff;
        position: relative;
        width: 100%;
        &:after {
          background: url(../images/common/icon_play.png) left top no-repeat;
          background-size: contain;
          content: '';
          height: 50px;
          left: 50%;
          position: absolute;
          top: 50%;
          transition: opacity .5s;
          transform: translate(-50%,-50%);
          width: 50px;
          pointer-events: none;
        }
        &:hover {
          &:after {
            opacity: .8;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      &--vertical {
        .imagebox {
          height: 100%;
        }
      }
    }
    .swiper-wrapper {
      align-items: center;
    }
  }
}
@media (--pc) {
  .format {
    .swiper-slide {
      margin: 0 10px !important;
      cursor: pointer;
    }
    &-video {
      margin: 50px auto 30px !important;
      &-container {
        width: 415px !important;
        height: 234px !important;
        &--vertical {
          width: 234px !important;
          height: 415px !important;
        }
      }
    }
    &-btn-prev {
      left: 120px !important;
    }
    &-btn-next {
      right: 120px !important;
    }
  }
}

@media (--sp) {
  .format {
    .sec-tl {
      white-space: nowrap;
    }
    .btm {
      margin: vw(40) auto 0;
    }
    .swiper-slide {
      margin: 0 vw(20) !important;
    }
    &-video {
      width: 100%;
      margin: vw(70) auto vw(60) !important;
      &-container {
        width: vw(500) !important;
        height: vw(285) !important;
        &--vertical {
          width: vw(285) !important;
          height: vw(500) !important;
        }
      }
    }
    &-btn-prev {
      left: vw(4) !important;
    }
    &-btn-next {
      right: vw(4) !important;
    }
  }
}
