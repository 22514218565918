@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';
@keyframes loop {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1400px -1444px;
  }
}
@keyframes loopSP {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -768px -792px;
  }
}
.mv {
  position: relative;
  overflow: hidden;
  &-bg {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: 0 100%;
  }
  &-layer01 {
    z-index: 5;
    background-color: #eaf7ff;
  }
  &-layer02 {
    z-index: 4;
    background-color: #fff;
  }
  &-layer03 {
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8);
  }
  &-txt {
    text-align: center;
    color: white;
    h2 {
      background-size: contain;
      background-repeat: no-repeat;

      overflow: hidden;
      white-space: nowrap;
      position: relative;
      span {
        transition: opacity 0.6s ease-out, transform 0.8s cubic-bezier(0.3, 0, 0.15, 1);
        display: inline-block !important;
      }
      &.active {
        span {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
  .chip {
    color: $base_blue;
    font-weight: bold;
    @mixin center;
  }
  &-slogan,
  .btn {
    position: relative;
    opacity: 0;
    transition: opacity 0.3s 0.8s ease-in;
    &.active {
      opacity: 1;
    }
  }
  &-logo {
    overflow: hidden;
    white-space: nowrap;
    span {
      transition: opacity 0.6s ease-out, transform 0.8s cubic-bezier(0.3, 0, 0.15, 1);
      display: inline-block !important;
    }
    &.active {
      span {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.mv-video {
  /* overflow: hidden; */
  position: relative;
  .revAni-inner {
    width: 100%;
    height: 100%;
  }
}

.mv-pagination {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0) !important;
  @mixin center;
  &-ele {
    flex-shrink: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background-color: #fff;
    }
    &.active {
      background-color: #fff;
    }
  }
}

.mv-video-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transform-origin: 50% 50%;
  display: none;
  &.active {
    @mixin center;
    opacity: 1;
  }
  &.virtual {
    @mixin center;
    left: 100%;
    transform: translate(0, -50%);
  }
  .inner {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    overflow: hidden;
    @mixin center;
  }
  &--vertical {
    video {
      width: auto !important;
      height: 100% !important;
    }
  }
  video {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (--pc) {
  .mv {
    height: calc(100vh - 75px);
    @mixin center;
    &-bg {
      width: 200%;
      height: 200%;
      background-image: url(../images/top/mv_pc.jpg);
      transform: translate(-50%, -50%) rotate(-15deg);
      animation: loop 60s infinite linear;
    }
    &-inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-txt {
      flex-shrink: 0;
      margin-right: pw(65);
      h2 {
        span {
          opacity: 0;
          transform: translateY(40px);
          &:nth-of-type(1) {
            @mixin bg '../images/top/mv_tl_pc_01.png';
          }
          &:nth-of-type(2) {
            @mixin bg '../images/top/mv_tl_pc_02.png';
          }
          &:nth-of-type(3) {
            @mixin bg '../images/top/mv_tl_pc_03.png';
          }
          &:nth-of-type(4) {
            @mixin bg '../images/top/mv_tl_pc_04.png';
          }
          &:nth-of-type(5) {
            @mixin bg '../images/top/mv_tl_pc_05.png';
          }
          &:nth-of-type(6) {
            @mixin bg '../images/top/mv_tl_pc_06.png';
          }
          &:nth-of-type(7) {
            @mixin bg '../images/top/mv_tl_pc_07.png';
          }
          &:nth-of-type(8) {
            @mixin bg '../images/top/mv_tl_pc_08.png';
          }
          &:nth-of-type(9) {
            @mixin bg '../images/top/mv_tl_pc_09.png';
          }
          &:nth-of-type(10) {
            @mixin bg '../images/top/mv_tl_pc_10.png';
          }
          &:nth-of-type(11) {
            @mixin bg '../images/top/mv_tl_pc_11.png';
          }
          &:nth-of-type(12) {
            @mixin bg '../images/top/mv_tl_pc_12.png';
          }
          &:nth-of-type(13) {
            @mixin bg '../images/top/mv_tl_pc_13.png';
          }
          &:nth-of-type(14) {
            @mixin bg '../images/top/mv_tl_pc_14.png';
          }
          &:nth-of-type(15) {
            @mixin bg '../images/top/mv_tl_pc_15.png';
          }
        }
      }
      p {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 4px;
        line-height: 1.5;
      }
      .btn {
        margin: 50px auto 0;
        @mixin btn 380px, 70px;
        font-size: 18px;
        padding-left: 45px;
        &:after {
          right: 30px;
          font-size: 11px;
        }
      }
    }
    /* .chip {
      background-color: #fff;
      color: $base_blue;
      font-size: 13px;
      width: 46px;
      height: 22px;
      margin-right: 10px;
    } */
    .mv-video {
      width: pw(468);
      height: pw(468);
    }
    .mv-video-container {
      width: pw(468) !important;
      height: pw(298) !important;
      .inner {
        width: pw(468) !important;
        height: pw(298) !important;
      }
      &--vertical {
        width: pw(298) !important;
        height: pw(468) !important;
        .inner {
          width: pw(298) !important;
          height: pw(468) !important;
        }
      }
    }
  }
  .mv-pagination {
    bottom: pw(35);
    &-ele {
      width: 6px;
      height: 6px;
      border: 1px solid white;
      margin: 0 5px;
    }
  }
}

@media (--tb) {
  .mv {
    /* &-logo {
      margin: 0 auto 25px;
      span {
        opacity: 0;
        transform: translateY(40px);
        &:nth-of-type(1) {
          @mixin bgTB '../images/top/mv_logo_pc_01.png';
        }
        &:nth-of-type(2) {
          @mixin bgTB '../images/top/mv_logo_pc_02.png';
        }
        &:nth-of-type(3) {
          @mixin bgTB '../images/top/mv_logo_pc_03.png';
        }
        &:nth-of-type(4) {
          @mixin bgTB '../images/top/mv_logo_pc_04.png';
        }
        &:nth-of-type(5) {
          @mixin bgTB '../images/top/mv_logo_pc_05.png';
        }
      }
    } */
    /* &-txt {
      margin-right: 65px;
      h2 {
        span {
          opacity: 0;
          transform: translateY(40px);
          &:nth-of-type(1) {
            @mixin bgTB '../images/top/mv_tl_pc_01.png';
          }
          &:nth-of-type(2) {
            @mixin bgTB '../images/top/mv_tl_pc_02.png';
          }
          &:nth-of-type(3) {
            @mixin bgTB '../images/top/mv_tl_pc_03.png';
          }
          &:nth-of-type(4) {
            @mixin bgTB '../images/top/mv_tl_pc_04.png';
          }
          &:nth-of-type(5) {
            @mixin bgTB '../images/top/mv_tl_pc_05.png';
          }
          &:nth-of-type(6) {
            @mixin bgTB '../images/top/mv_tl_pc_06.png';
          }
          &:nth-of-type(7) {
            @mixin bgTB '../images/top/mv_tl_pc_07.png';
          }
          &:nth-of-type(8) {
            @mixin bgTB '../images/top/mv_tl_pc_08.png';
          }
          &:nth-of-type(9) {
            @mixin bgTB '../images/top/mv_tl_pc_09.png';
          }
          &:nth-of-type(10) {
            @mixin bgTB '../images/top/mv_tl_pc_10.png';
          }
          &:nth-of-type(11) {
            @mixin bgTB '../images/top/mv_tl_pc_11.png';
          }
          &:nth-of-type(12) {
            @mixin bgTB '../images/top/mv_tl_pc_12.png';
          }
          &:nth-of-type(13) {
            @mixin bgTB '../images/top/mv_tl_pc_13.png';
          }
          &:nth-of-type(14) {
            @mixin bgTB '../images/top/mv_tl_pc_14.png';
          }
          &:nth-of-type(15) {
            @mixin bgTB '../images/top/mv_tl_pc_15.png';
          }
        }
      }
      p {
        margin-top: 20px;
        font-size: rem(20);
        letter-spacing: 4px;
      }
      .btn {
        margin: 50px auto 0;
        @mixin btn 380px, 70px;
        font-size: 18px;
        padding-left: 45px;
        &:after {
          right: 30px;
          font-size: 11px;
        }
      }
    }
    .chip {
      background-color: #fff;
      color: $base_blue;
      font-size: 13px;
      width: 46px;
      height: 22px;
      margin-right: 10px;
    } */
    .mv-txt {
      margin-right: 65px;
      /* &:before{
          font-size: vw(24);
          letter-spacing: 4px;
          left: vw(60);
          z-index:2;
        } */
    }
    .mv-video {
      /* overflow: hidden; */
      width: 468px;
      height: 468px;
    }
    .mv-video-container {
      width: 468px !important;
      height: 298px !important;
      .inner {
        width: 468px !important;
        height: 298px !important;
      }
      &--vertical {
        width: 298px !important;
        height: 468px !important;
        .inner {
          width: 298px !important;
          height: 468px !important;
        }
      }
    }
  }
  .mv-pagination {
    bottom: 35px;
    &-ele {
      width: 6px;
      height: 6px;
      border: 1px solid white;
      margin: 0 5px;
    }
  }
}

@media (--sp) {
  .mv {
    width: 100%;
    height: vw(1020);
    position: relative;
    padding-top: vw(45);
    &-bg {
      width: 160%;
      height: 160%;
      background-image: url(../images/top/mv_sp.jpg);
      transform: translate(-50%, -50%) rotate(-15deg);
      animation: loop 40s infinite linear;
    }
    &-txt {
      margin: vw(60) auto 0;
      h2 {
        margin: 0 auto;
        span {
          opacity: 0;
          transform: translateX(20px);
          &:nth-of-type(1) {
            @mixin bgV '../images/top/mv_tl_sp_01.png';
          }
          &:nth-of-type(2) {
            @mixin bgV '../images/top/mv_tl_sp_02.png';
          }
          &:nth-of-type(3) {
            @mixin bgV '../images/top/mv_tl_sp_03.png';
          }
          &:nth-of-type(4) {
            @mixin bgV '../images/top/mv_tl_sp_04.png';
          }
          &:nth-of-type(5) {
            @mixin bgV '../images/top/mv_tl_sp_05.png';
          }
          &:nth-of-type(6) {
            @mixin bgV '../images/top/mv_tl_sp_06.png';
          }
          &:nth-of-type(7) {
            @mixin bgV '../images/top/mv_tl_sp_07.png';
          }
          &:nth-of-type(8) {
            @mixin bgV '../images/top/mv_tl_sp_08.png';
          }
          &:nth-of-type(9) {
            @mixin bgV '../images/top/mv_tl_sp_09.png';
          }
          &:nth-of-type(10) {
            @mixin bgV '../images/top/mv_tl_sp_10.png';
          }
          &:nth-of-type(11) {
            @mixin bgV '../images/top/mv_tl_sp_11.png';
          }
          &:nth-of-type(12) {
            @mixin bgV '../images/top/mv_tl_sp_12.png';
          }
          &:nth-of-type(13) {
            @mixin bgV '../images/top/mv_tl_sp_13.png';
          }
          &:nth-of-type(14) {
            @mixin bgV '../images/top/mv_tl_sp_14.png';
          }
          &:nth-of-type(15) {
            @mixin bgV '../images/top/mv_tl_sp_15.png';
          }
        }
      }
      p {
        margin: vw(30) auto 0;
        font-size: vw(24);
        letter-spacing: vw(4);
        line-height: 1.5;
      }
      .btn {
        @mixin btn vw(570), vw(106);
        position: absolute;
        bottom: vw(120);
        left: 50%;
        transform: translate(-50%, 0);
        font-size: vw(28);
        padding-left: vw(70);
        &:after {
          right: vw(40);
          font-size: vw(20);
        }
      }
    }
    /* .chip {
      background-color: #fff;
      font-size: vw(20);
      width: vw(70);
      height: vw(34);
      margin-right: vw(16);
    } */
    .mv-video {
      /* overflow: hidden; */
      margin: vw(35) auto;
      width: vw(468);
      height: vw(468);
    }
    .mv-video-container {
      width: vw(468) !important;
      height: vw(298) !important;
      .inner {
        width: vw(468) !important;
        height: vw(298) !important;
      }
      &--vertical {
        width: vw(298) !important;
        height: vw(468) !important;
        .inner {
          width: vw(298) !important;
          height: vw(468) !important;
        }
      }
    }
  }
  .mv-pagination {
    bottom: vw(30);
    &-ele {
      width: vw(12);
      height: vw(12);
      border: 1px solid white;
      margin: 0 vw(10);
    }
  }
}
