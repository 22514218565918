@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';
@keyframes moveBg {
  from {
    background-position: 0;
  }
  to {
    background-position: 200%;
  }
}
#company-clipImage {
  transform-origin: center;
  animation: rotation 10s infinite linear;
}
.company {
  position: relative;
  &-illus {
    position: relative;
    padding-top: 34.28%;
    width: 100%;
    height: 0;
    background-image: url('../images/top/mv_noblur.jpg');
    background-repeat: repeat-x;
    background-size: auto 100%;
    /* animation: moveBg 10s infinite linear; */
    &:after {
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: url('../images/top/company-mask.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 0;
    }
  }
  &-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &-clipPath {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  &-decorate {
    display: block;
    position: absolute;
    background-color: #bebebe;
    transform: translate(-50%, 0);
    left: 50%;
  }
  &-tl {
    white-space: nowrap;
  }
}

@media (--pc) {
  .company {
    padding-top: 100px;
    &-illus {
      margin: 0 auto 85px;
    }
    &-tl {
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 2px;
      font-size: rem(30);
      text-align: center;
    }
    &-subtl {
      margin-top: 40px;
      line-height: 1.75;
      text-align: center;
      font-size: rem(16);
    }
    .btn {
      margin: 50px auto 0;
      @mixin btn 290px, 62px;
      letter-spacing: 2px;
      font-size: rem(16);
      padding-left: 95px;
      &:after {
        display: none;
        right: 25px;
        font-size: rem(11);
      }
      i{
        display: inline-block;
        margin-left: 80px;
      }
    }
    &-decorate {
      width: 1px;
      height: 120px;
      bottom: -50px;
    }
  }
}

@media (--sp) {
  .company {
    padding-top: vw(90);
    &-illus {
      margin: 0 auto vw(90);
    }
    &-tl {
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: vw(4);
      font-size: vw(32);
      text-align: center;
    }
    &-subtl {
      margin-top: vw(60);
      line-height: 1.75;
    }
    .btn {
      margin: vw(55) auto 0;
      @mixin btn vw(560), vw(92);
      text-align: center;
      letter-spacing: vw(4);
      padding-left: vw(210);
      &:after {
        display: none;
        right: vw(35);
        font-size: vw(22);
      }
      i{
        display: inline-block;
        margin-left: vw(140);
      }
    }
    &-decorate {
      width: 1px;
      height: vw(170);
      bottom: vw(-85);
    }
  }
}
